import { useState, useEffect } from 'react';
import {
  bool,
  func,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

function DateControl(props) {
  const {
    disabled,
    events = {},
    error,
    helperText,
    label,
    name,
    settings = {},
    size,
    onChange,
    variant,
    value,
  } = props;

  const defaultDate = value ? new Date(value) : new Date();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultDate);

  useEffect(() => {
    if (!value) {
      handleChange(defaultDate);
    }
  }, []);

  let format = {
    year: 'YYYY',
    month: 'YYYY MMMM',
  }[settings?.minPeriod] || 'yyyy-MM-DD';

  if (settings?.hasTime) {
    format += ' HH:mm';
  }

  const openTo = {
    year: 'year',
    month: 'month',
  }[settings?.minPeriod] || 'date';

  const views = {
    year: ['year'],
    month: ['year', 'month'],
  }[settings?.minPeriod];

  const handleChange = (date) => {
    try {
      let value = new Date(date);

      if (settings?.minPeriod === 'year') {
        value = new Date(value.getFullYear(), 0, 1);
      } else if (settings?.minPeriod === 'month') {
        value = new Date(value.getFullYear(), value.getMonth(), 1);
      } else if (!settings?.hasTime) {
        value = new Date(value.getFullYear(), value.getMonth(), value.getDate());
      }

      if (settings?.onlyDate) {
        value = value.toISOString().split('T').shift();
      }

      const target = {
        name,
        value,
      };
      onChange({
        target,
      });

      if (events.onChange) {
        events.onChange(target);
      }

      setSelected(value);
    } catch (err) {
      setSelected(date);
    }
  };

  const Component = settings?.hasTime
    ? KeyboardDateTimePicker
    : KeyboardDatePicker;

  return (
    <Component
      ampm={false}
      autoOk={true}
      disabled={disabled}
      error={error}
      format={format}
      fullWidth={true}
      helperText={helperText}
      inputVariant={variant}
      InputLabelProps={{
        shrink: `${selected}` !== 'Invalid Date',
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      label={label}
      name={name}
      onChange={handleChange}
      onClick={() => !disabled && setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      openTo={openTo}
      size={size}
      value={selected}
      variant="inline"
      views={views}
    />
  );
}

DateControl.defaultProps = {
  disabled: false,
  error: false,
  onChange: () => { },
  onBlur: () => { },
};

DateControl.propTypes = {
  disabled: bool,
  error: bool,
  events: object,
  helperText: string,
  label: string,
  name: string,
  settings: shape({
    hasTime: bool,
    minPeriod: oneOf([
      'year',
      'month',
      'day',
    ]),
    onlyDate: bool,
  }),
  size: oneOf([
    'small',
    'medium',
  ]),
  onChange: func,
  onBlur: func,
  value: string,
  variant: oneOf([
    'outlined',
    'standard',
  ]),
};

export default DateControl;
